import React, { useState, useEffect } from 'react';
import { useMoralis } from "react-moralis";

const nft_contract_address = "0x73374074a68f14082b02f07cf5977d1d881863cc"

function NFTCreate() {
  const { Moralis, web3, authenticate, isAuthenticated, logout, account, chainId } = useMoralis();

  useEffect(() => {
    console.log(account)
  });

  // https://docs.moralis.io/moralis-server/cloud-code/cloud-functions#web3
  const upload = async () => {
    const fileInput = document.getElementById("file");
    const data = fileInput.files[0];
    const imageFile = new Moralis.File(data.name, data);
    await imageFile.saveIPFS();
    const imageURI = imageFile.ipfs();
    const metadata = {
      "name":document.getElementById("name").value,
      "description":document.getElementById("description").value,
      "image":imageURI
    }
    const metadataFile = new Moralis.File("metadata.json", {base64 : btoa(JSON.stringify(metadata))});
    await metadataFile.saveIPFS();
    const metadataURI = metadataFile.ipfs();
    console.log(metadataURI);
    const txt = await mintToken(metadataURI).then(notify)
  }

  const mintToken = async (_uri) => {
    // JSON interface object - Application Binary Interface (ABI)
    const abi = {
      name: "mintToken",
      type: "function",
      inputs: [{
        type: 'string',
        name: 'tokenURI'
        }]
    };
    // Cannot read properties of undefined (reading 'erc721')
    //const abi = Moralis.Web3.abis.erc721;
    const encodedFunction = web3.eth.abi.encodeFunctionCall(abi, [_uri]);
  
    // does not recognise the abi
    //const contract = new web3.eth.Contract(abi, nft_contract_address);
    const txt = await web3.eth.sendTransaction({
      from: account,
      to: nft_contract_address,
      method: 'eth_sendTransaction',
      data: encodedFunction,
    });
    return txt
  }
  
  const notify = async (_txt) => {
    document.getElementById("resultSpace").innerHTML =  
    `<input disabled = "true" id="result" type="text" className="form-control" placeholder="Description" aria-label="URL" aria-describedby="basic-addon1" value="Your NFT was minted in transaction ${_txt}">`;
  }

  return (
    <div>
      <h1>Mint an NFT</h1>
      <hr/>
      <hr/>
      <div className="mb-3">      
        <div className="form-group">
          <div className="input-group mb-3">
            <input id="name" type="text" className="form-control" placeholder="NFT Name" aria-label="URL" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <input id="description" type="text" className="form-control" placeholder="Description" aria-label="URL" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <input type="file" id="file" />
          </div>
        </div>
        <div>  
          <button className="btn btn-primary" id="upload" onClick={()=>upload()}>Upload and mint</button>
        </div>
        <hr/>
          <div className="input-group mb-3" id="resultSpace">
          </div>
      </div>
    </div>
  );
}

export default NFTCreate;