import { useMoralis, useERC20Balances } from "react-moralis";
import { Skeleton, Table } from "antd";
import { getEllipsisTxt } from "../helpers/formatters";
import { useERC20Transfers } from "../hooks/useERC20Transfers";
import { getExplorer } from "../helpers/networks";
import "antd/dist/antd.css";
import abi from "./abi_usdc.js";

const styles = {
  title: {
    fontSize: "30px",
    fontWeight: "700",
  },
};
function ERC20Balance(props) {
  const { data: assets } = useERC20Balances(props);
  const { ERC20Transfers, chainId } = useERC20Transfers();
  const { Moralis } = useMoralis();

  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      render: (logo) => (
        <img
          src={logo || "https://etherscan.io/images/main/empty-token.png"}
          alt="nologo"
          width="28px"
          height="28px"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name,
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (symbol) => symbol,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (value, item) =>
        parseFloat(Moralis.Units.FromWei(value, item.decimals).toFixed(6)),
    },
    {
      title: "Address",
      dataIndex: "token_address",
      key: "token_address",
      render: (address) => getEllipsisTxt(address, 5),
    },
  ];

  const columns2 = [
    {
      title: "Token",
      dataIndex: "address",
      key: "address",
      render: (token) => getEllipsisTxt(token, 8),
    },
    {
      title: "From",
      dataIndex: "from_address",
      key: "from_address",
      render: (from) => getEllipsisTxt(from, 8),
    },
    {
      title: "To",
      dataIndex: "to_address",
      key: "to_address",
      render: (to) => getEllipsisTxt(to, 8),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value, item) => parseFloat(Moralis.Units.FromWei(value, item.decimals).toFixed(6)),
    },
    {
      title: "Hash",
      dataIndex: "transaction_hash",
      key: "transaction_hash",
      render: (hash) => (
        <a href={`${getExplorer(chainId)}tx/${hash}`} target="_blank" rel="noreferrer">
          View Transaction
        </a>
      ),
    },
  ];

  let key = 0;
  return (
    <div style={{ width: "65vw", padding: "15px" }}>
      <h1 style={styles.title}>💰Token Balances</h1>
      <Skeleton loading={!assets}>
        <Table
          dataSource={assets}
          columns={columns}
          rowKey={(record) => {
            return record.token_address;
          }}
        />
      </Skeleton>
      <h1>Get USDC</h1>
      <div style={{width: "500px"}}>
        <div>Amount in AVAX <input id="avaxAmount" type="text"/></div>
        <div>Amount in USDC <input id="usdcAmount" type="text" disabled/></div>
        <div><button onClick={async ()=>{
          /*const options = {
            contractAddress: "0xcfEaBe9C6d0DAAc5A2214Ba942afB8C8dABF277F",
            functionName: "getConversionRate",
            abi,
            params:{avaxAmount: String(AVAX_amount)},
          };*/
          const msgValue = parseInt(document.getElementById("avaxAmount").value)*(10**18);
          console.log(msgValue);
          const options = {
            contractAddress: "0xcfEaBe9C6d0DAAc5A2214Ba942afB8C8dABF277F",
            functionName: "SwapAVAX",
            abi,
            params:{},
            msgValue
            }
          const transaction = await Moralis.executeFunction(options); // see https://docs.moralis.io/moralis-server/web3/web3
          console.log(transaction);
        }}>Change AVAX to USDC</button></div>
        <p>Avalanche supports USDC but we cannot get it on Fuji. On Mainnet we recommend <a target="_blank" href="https://traderjoexyz.com/#/trade">Trader Joe</a> or <a target="_blank" href="https://app.pangolin.exchange/#/swap">Pangolin</a></p>
      </div>
      <h1>💸 Transactions</h1>
      <Skeleton loading={!ERC20Transfers}>
        <Table
          dataSource={ERC20Transfers}
          columns={columns2}
          rowKey={(record) => {
            key++;
            return `${record.transaction_hash}-${key}`;
          }}
        />
      </Skeleton>
    </div>
  );
}
export default ERC20Balance;
