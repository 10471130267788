import React from "react";
import { useMoralis } from "react-moralis";


function ERC20Transfers() {


  let key = 0;
  return (
    <div style={{ width: "65vw", padding: "15px" }}>
    </div>
  );
}

export default ERC20Transfers;
